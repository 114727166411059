import { BigNumber, utils } from "ethers";
import { usePresaleData, fmt, getStageName } from "../utils/utils";

export default function MainBanner() {
    const {
        stage,
        soldOnStage,
        currentPrice,
        remainingOnStage,
        usdCurrent,
        usdNext,
        progressShare,
        nextPrice,
    } = usePresaleData();

    return (
        <div className="betablock2">
            <div className="betablock2_text1">
                <div>
                    <span>{getStageName(stage)}</span>
                </div>
            </div>
            <div className="sop1">
                <div>
                    <div className="sop2">{getStageName(stage)}</div>
                    <div className="sop3">current stage</div>
                </div>
                <div>
                    <div className="sop4">${fmt(usdCurrent)}</div>
                    <div className="sop5">
                        / ${fmt(usdNext)} <span>raised</span>
                    </div>
                </div>
            </div>
            <div className="shevronbl1" content={{ "&::after": { width: 50 } }}>
                <div className="shevronbl2">
                    <span>{progressShare}%</span> sold
                </div>
                <div
                    style={{
                        height: "4px",
                        width: `${100 - progressShare}%`,
                        background: "#318134",
                        bottom: 0,
                        right: 0,
                        zIndex: 2,
                        position: "absolute",
                    }}
                ></div>
                <div>
                    <div className="shevronbl3">{100 - progressShare}%</div>
                    <div className="shevronbl4">remaining</div>
                </div>
            </div>
            <div
                className="usd1"
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <span>${utils.formatUnits(currentPrice)}</span> USDT = $1
                    MLVL
                </div>
                {stage < 8 && (
                    <div>
                        Next stage:
                        <span style={{ color: "rgb(0 255 10)" }}>
                            {" "}
                            ${utils.formatUnits(nextPrice)}
                        </span>
                    </div>
                )}
            </div>
            <div className="usd2">
                <div className="usd3">
                    <span>{fmt(soldOnStage)} </span> Tokens Sold
                </div>
                <div className="usd4">
                    only <span>{fmt(remainingOnStage)} </span>
                    tokens remains
                </div>
            </div>

            <a href="/presale" className="claimbut">
                <span>
                    <span>
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 9V6H4.5C4.10218 6 3.72064 5.84196 3.43934 5.56066C3.15804 5.27936 3 4.89782 3 4.5C3 3.675 3.675 3 4.5 3H13.5V6"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                            <path
                                d="M3 4.5V13.5C3 14.325 3.675 15 4.5 15H15V12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                            <path
                                d="M13.5 9C13.1022 9 12.7206 9.15804 12.4393 9.43934C12.158 9.72064 12 10.1022 12 10.5C12 11.325 12.675 12 13.5 12H16.5V9H13.5Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    </span>
                    Buy Tokens
                </span>
            </a>
            <div className="htbb2">
                <a href="/how_to_buy">How to Buy?</a>
            </div>
        </div>
    );
}
