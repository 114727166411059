import React from "react";

export default function FaqHolder({ initial, title, children }) {
    const [active, setActive] = React.useState(initial);

    return (
        <div
            className={"faqholder" + (active ? " active" : "")}
            id="faq_0"
            onClick={() => setActive(!active)}
        >
            <a href="#null" dataTarget="faq_1" className="faqbut">
                {title}
            </a>
            <div className="faqcontent">{children}</div>
        </div>
    );
}
