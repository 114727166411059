import React from "react";
import { fmt, getStageName, truncateAddress, usePresale } from "../utils/utils";
import { BigNumber } from "ethers";
import { toast } from "react-toastify";

function showToast(text, text2) {
    navigator.clipboard.writeText(text);
    toast.success(text2 + " copied to clipboard");
}

export default function Calculator() {
    const [active, setActive] = React.useState(false);

    const [stage, setStage] = React.useState(0);
    const stages = [0, 1, 2, 3, 4, 5, 6, 7, 8];

    const [usdAmount, setUsdAmount] = React.useState(0);

    const [price, setPrice] = React.useState(BigNumber.from(0));
    const presale = usePresale();

    React.useEffect(() => {
        if (presale) {
            presale.token_price(stage).then((p) => setPrice(p));
        }
    }, [presale, stage]);

    let amountOut = BigNumber.from(0);
    if (price && price.gt(0)) {
        amountOut = BigNumber.from(10).pow(18).mul(usdAmount).div(price);
    }

    return (
        <div className="calculatorblock movedbg">
            <div className="center_header22">
                <h2 className="mainheader1 whitetext222">Calculator tool</h2>
            </div>
            <div className="calctext1">
                Get your Early Bird tokens now! Use our calculator to compare
                <br />
                stage prices and see how much you can gain!
            </div>
            <div className="calcholder">
                <div className="calcholder2">
                    <div className="calcheder">presale purchase calculator</div>
                    <div className="inputsholder">
                        <div className="inputitems">
                            <div className="inptheader">
                                1. Select a Presale Stage:
                            </div>
                            <div className="dropdown">
                                <a
                                    href="#null"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className="myselect"
                                >
                                    {getStageName(stage)}
                                </a>
                                <ul className="dropdown-menu">
                                    {stages.map((s) => (
                                        <li key={s}>
                                            <a
                                                className="dropdown-item"
                                                onClick={() => setStage(s)}
                                            >
                                                {getStageName(s)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="inputitems">
                            <div className="inptheader">
                                2. Enter your amount:
                            </div>
                            <div className="inputlastholder">
                                <span>
                                    <img src="img/dollar2.svg" />
                                </span>
                                <input
                                    type="text"
                                    className="inputlast"
                                    value={usdAmount}
                                    onChange={(event) => {
                                        if (event.target.value === "") {
                                            setUsdAmount(0);
                                        } else {
                                            try {
                                                const value = Number.parseInt(
                                                    event.target.value
                                                );
                                                if (value) {
                                                    setUsdAmount(value);
                                                }
                                            } catch {}
                                        }
                                    }}
                                    placeholder="0"
                                />
                            </div>
                        </div>
                        <div className="inputitems">
                            <div className="inptheader">
                                3. How many do you get:
                            </div>
                            <div className="inputlastholder">
                                <span className="span2">$mlvl</span>
                                <input
                                    type="text"
                                    className="inputlast last2"
                                    value={fmt(amountOut)}
                                    placeholder="0,000"
                                />
                            </div>
                        </div>
                        <div className="inputitems">
                            <div className="inptheader">
                                Compare the stages:
                            </div>
                            <button
                                type="button"
                                className="but8"
                                onClick={() => setActive(!active)}
                            >
                                {active ? "Hide Table" : "Show Table"}
                            </button>
                        </div>
                    </div>
                    <div
                        className="calctableholder"
                        id="calctableholder"
                        style={{ display: active ? "block" : null }}
                    >
                        <table className="calctable">
                            <thead>
                                <tr>
                                    <th>Stage</th>
                                    <th>Price</th>
                                    <th>Tokens</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Beta Sale</td>
                                    <td>0,008</td>
                                    <td>140m</td>
                                    <td>$1,120,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 1</td>
                                    <td>0,010</td>
                                    <td>157.7m</td>
                                    <td>$2,695,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 2</td>
                                    <td>0,012</td>
                                    <td>157.7m</td>
                                    <td>$4,585,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 3</td>
                                    <td>0,013</td>
                                    <td>100m</td>
                                    <td>$5,885,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 4</td>
                                    <td>0,014</td>
                                    <td>100m</td>
                                    <td>$7,285,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 5</td>
                                    <td>0,015</td>
                                    <td>100m</td>
                                    <td>$8,835,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 6</td>
                                    <td>0,017</td>
                                    <td>100m</td>
                                    <td>$10,535,000</td>
                                </tr>
                                <tr>
                                    <td>Stage 7</td>
                                    <td>0,018</td>
                                    <td>100m</td>
                                    <td>$12,385,000</td>
                                </tr>
                                <tr className="activrow">
                                    <td>Stage 8</td>
                                    <td>0,020</td>
                                    <td>200m</td>
                                    <td>$16,385,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="calcholder3">
                    <div className="calc3_header1">annoucements & info</div>
                    <div className="butholder11">
                        <a
                            href="https://t.me/Metalevel_ai"
                            target="_blank"
                            className="but10"
                        >
                            <span>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.3583 9.38245C11.3857 9.78701 9.44176 10.6243 6.52656 11.8945C6.05318 12.0827 5.8052 12.2669 5.78262 12.4469C5.74447 12.7513 6.12558 12.8711 6.64454 13.0343C6.71513 13.0565 6.78828 13.0795 6.86326 13.1039C7.37385 13.2698 8.06067 13.464 8.41772 13.4717C8.7416 13.4787 9.10309 13.3452 9.50218 13.0711C12.2259 11.2325 13.632 10.3032 13.7202 10.2832C13.7825 10.269 13.8688 10.2512 13.9273 10.3032C13.9858 10.3552 13.98 10.4536 13.9738 10.48C13.9361 10.641 12.4401 12.0318 11.6659 12.7515C11.4246 12.9759 11.2534 13.135 11.2184 13.1714C11.14 13.2528 11.0601 13.3298 10.9833 13.4039C10.509 13.8611 10.1532 14.204 11.003 14.764C11.4113 15.0331 11.7381 15.2556 12.0641 15.4776C12.4201 15.7201 12.7752 15.9619 13.2347 16.2631C13.3517 16.3398 13.4635 16.4195 13.5724 16.4971C13.9867 16.7925 14.3589 17.0579 14.8188 17.0155C15.086 16.991 15.362 16.7397 15.5022 15.9903C15.8335 14.2193 16.4847 10.3821 16.6352 8.80082C16.6483 8.66229 16.6318 8.48499 16.6184 8.40716C16.6051 8.32932 16.5773 8.21843 16.4761 8.13634C16.3563 8.03912 16.1713 8.01862 16.0886 8.02008C15.7125 8.0267 15.1354 8.22736 12.3583 9.38245Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            Telegram
                        </a>
                        <a
                            href="https://twitter.com/MetaLevelSystem"
                            target="_blank"
                            className="but10 but11"
                        >
                            <span>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.9525 7.91998C19.9647 8.0963 19.9647 8.27261 19.9647 8.45055C19.9647 13.8724 15.8371 20.1255 8.28966 20.1255V20.1223C6.06013 20.1255 3.8769 19.4869 2 18.2828C2.32419 18.3218 2.65001 18.3413 2.97664 18.3421C4.82429 18.3437 6.61913 17.7238 8.07272 16.5822C6.31688 16.5489 4.77717 15.404 4.23928 13.7327C4.85436 13.8513 5.48812 13.8269 6.09181 13.662C4.17753 13.2753 2.80033 11.5934 2.80033 9.64007C2.80033 9.6222 2.80033 9.60513 2.80033 9.58807C3.37071 9.90576 4.00934 10.0821 4.6626 10.1016C2.85964 8.89662 2.30388 6.49808 3.39265 4.6228C5.47593 7.18628 8.54967 8.74468 11.8493 8.90962C11.5186 7.48447 11.9703 5.99108 13.0364 4.98925C14.689 3.43572 17.2882 3.51535 18.8418 5.16719C19.7607 4.986 20.6415 4.64881 21.4475 4.17105C21.1412 5.12088 20.5001 5.9277 19.6437 6.4404C20.457 6.34452 21.2517 6.12677 22 5.79445C21.4491 6.61996 20.7552 7.33903 19.9525 7.91998Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            Twitter
                        </a>
                    </div>
                    <div className="minitext">
                        Contract addresses for information purpose only. See our{" "}
                        <a href="">How to Buy MLVL</a> guide to purchase
                    </div>
                    <div className="contractholder">
                        <div className="contractblock1">ICO Contract</div>
                        <div className="contractblock2">
                            <span>
                                {truncateAddress(
                                    process.env.REACT_APP_PRESALE_ADDRESS
                                )}
                            </span>
                            <a
                                href="#null"
                                onClick={() =>
                                    showToast(
                                        process.env.REACT_APP_PRESALE_ADDRESS,
                                        "ICO Contract"
                                    )
                                }
                            >
                                <img src="img/copycat_1.svg" />
                            </a>
                        </div>
                    </div>
                    <div className="contractholder">
                        <div className="contractblock1">$MLVL Contract</div>
                        <div className="contractblock2">
                            <span>
                                {truncateAddress(
                                    process.env.REACT_APP_TOKEN_ADDRESS
                                )}
                            </span>
                            <a
                                href="#null"
                                onClick={() =>
                                    showToast(
                                        process.env.REACT_APP_TOKEN_ADDRESS,
                                        "$MLVL Contract"
                                    )
                                }
                            >
                                <img src="img/copycat_1.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
