import React from "react";
import Modal from "react-modal";

import PurchaseProcess from "./PurchaseProcess";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "#232E47",
        border: "none",
        borderRadius: 10,
    },
    overlay: { background: "rgba(200,200,200,0.3)" },
};

export default function Buy() {
    const [currency, setCurrency] = React.useState("none");
    const [pending, setPending] = React.useState(false);

    // On close
    let onClose = null;
    if (!pending) {
        onClose = () => setCurrency("none");
    }

    return (
        <React.Fragment>
            <div className="tic tic2">
                <a
                    href="#null"
                    className="but5"
                    onClick={() => setCurrency("usdt")}
                >
                    Buy with USDT
                </a>
            </div>
            <div className="tic tic2">
                <a
                    href="#null"
                    className="but5"
                    onClick={() => setCurrency("eth")}
                >
                    Buy with ETH
                </a>
            </div>
            <Modal
                isOpen={currency !== "none"}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel="Buy Modal"
            >
                <PurchaseProcess
                    currency={currency}
                    setCurrency={setCurrency}
                    pending={pending}
                    setPending={setPending}
                />
            </Modal>
        </React.Fragment>
    );
}
