import React from "react";
import { useMedia } from "../utils/utils";

export default function Media() {
    const media = useMedia();

    return (
        <React.Fragment>
            <section class="first_block htbpad">
                <div class="container-xl">
                    <h1 class="betablocktext2 htbheader">PRESS & MEDIA</h1>
                    <div class="htbimg">
                        <img src="img/htbbull.png" />
                    </div>
                </div>
            </section>
            <section class="maintext">
                <div class="container-xl">
                    <div class="joinp_container">
                        <div class="joinp_text">
                            Actively participate in our presale event and grab a
                            chance to win attractive rewards every day
                        </div>
                        <div class="butholder6">
                            <a href="/presale" class="but2">
                                <span>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V5.625L10.875 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V6"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M10.5 1.5V6H15"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M1.5 11.25H9"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M6.75 13.5L9 11.25L6.75 9"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </span>
                                Join Presale
                            </a>
                        </div>
                    </div>
                    {media.map((m) => (
                        <a class="mediaholder" href={m.link} target="_blank">
                            <div class="mediaimg">
                                <img src={m.img} />
                            </div>
                            <div class="gth">
                                <div class="mediatext">{m.description}</div>
                                <div class="mediatext2">
                                    {new Date(m.dat).toDateString()}
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </section>
        </React.Fragment>
    );
}
