import React from "react";

export default function HowToBuy() {
    return (
        <React.Fragment>
            <section class="first_block htbpad">
                <div class="container-xl">
                    <h1 class="betablocktext2 htbheader">HOW TO BUY $MLVL</h1>
                    <div class="htbimg">
                        <img src="img/htbbull.png" />
                    </div>
                </div>
            </section>
            <section class="maintext">
                <div class="container-xl">
                    <h2 class="mainheader1 htbpad2">
                        BEGINNER'S GUIDE ON BUYING <span>$MLVL</span>
                    </h2>
                    <div class="joinp_container">
                        <div class="joinp_text">
                            Actively participate in our presale event and grab a
                            chance to win attractive rewards every day
                        </div>
                        <div class="butholder6">
                            <a href="/presale" class="but2">
                                <span>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V5.625L10.875 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V6"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M10.5 1.5V6H15"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M1.5 11.25H9"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M6.75 13.5L9 11.25L6.75 9"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </span>
                                Join Presale
                            </a>
                        </div>
                    </div>
                    <div class="footerheader pad16">step 1</div>
                    <p class="mainp1">
                        The first step is to ensure you have one of the
                        supported wallets by Wallet Connect installed on your
                        browser. For a smoother purchasing experience, we
                        suggest purchasing on desktop through MetaMask. However,
                        if you are purchasing on mobile, we recommend using
                        Trust Wallet. Copy{" "}
                        <a href="">https://metalevel-ai.com/</a> and connect
                        through the built-in browser.
                    </p>
                    <div class="footerheader pad16">step 2</div>
                    <p class="mainp1">
                        On choosing your preferred wallet provider and visiting
                        our DEX, click 'Connect Wallet' and select the
                        appropriate option. For mobile, select 'Wallet Connect'
                        to connect your device. There will then be 3 options to
                        choose from:
                    </p>
                    <div class="coloredblocks ">
                        <div class="coloredblock">
                            <div class="cartheader">BUY ETH WITH CARD</div>
                            <div class="cartheader2">
                                Here you can purchase ETH through our partner
                                Transak, then you can swap your ETH for $MLVL.
                                Head to the 'BUY $ETH HERE’ section and follow
                                the steps on screen. Be sure to select ETH as
                                the purchasing currency. A minimum purchase of
                                $30 worth of ETH is recommended to cover your
                                $MLVL purchase.{" "}
                            </div>
                        </div>

                        <div class="coloredblock col2">
                            <div class="cartheader">BUY $MLVL WITH ETH</div>
                            <div class="cartheader2">
                                With sufficient ETH in your chosen wallet, you
                                will now be able to swap your ETH for $MLVL.
                                Inside the ‘TRADE WITH $ETH’ panel, enter the
                                amount of $MLVL you would like to purchase ($30
                                ETH is the recommended minimum), click 'BUY'.
                                You will then be shown the cost of gas and asked
                                to confirm the transaction by your wallet
                                provider.
                            </div>
                        </div>

                        <div class="coloredblock col3">
                            <div class="cartheader">BUY $MLVL WITH USDT</div>
                            <div class="cartheader2">
                                First, choose the amount of $MLVL you would like
                                to purchase inside the ‘BUY WITH $USDT’ panel
                                ($30 USDT is the recommended minimum) then click
                                ‘1.APPROVE’ to give you purchase permission.
                                Following approval inside your wallet, click
                                ‘2.BUY’ and confirm the transaction inside your
                                wallet. Ensure you have enough ETH in your
                                wallet to cover the gas fee also.
                            </div>
                        </div>
                    </div>
                    <div class="footerheader pad16">step 3</div>
                    <p class="mainp1 p2">
                        You can claim your $MLVL tokens at the end of the
                        presale. Details will be released closer to the time.
                        Once the presale period has concluded, you must visit{" "}
                        <a href="">https://metalevel-ai.com/</a> and click the
                        'Claim' button.
                    </p>
                    <h2 class="mainheader1 htbpad2">
                        CLAIMING YOUR <span>$MLVL</span> TOKENS
                    </h2>
                    <div class="joinp_container joitext3">
                        <div>
                            YOUR <span>$MLVL</span> TOKENS WILL BE AVAILABLE
                            ONCE THE PRESALE PERIOD HAS FINISHED.
                        </div>
                    </div>
                    <ol class="numlist">
                        <li>
                            Ensure the wallet used to purchase your $MLVL is
                            connected to the Metalevel exchange.
                        </li>
                        <li>
                            Once connected you will see how much $MLVL you have
                            purchased and is available to claim with the
                            connected wallet.
                        </li>
                        <li>In the navigation bar, click CLAIM.</li>
                        <li>
                            Your wallet will then ask you to approve the claim
                            transaction. There will be a small gas fee in order
                            to claim your tokens. A gas fee is paid to network
                            validators for their services to the blockchain to
                            carry out transactions.
                        </li>
                        <li>
                            On approval of the transaction, you will see a
                            success notification pop up on the Metalevel
                            exchange.
                        </li>
                        <li>
                            Your $MLVL will now be showing inside your wallet. A
                            small notice will now also be present next to the
                            claim button on the site confirming your claim.
                        </li>
                    </ol>
                </div>
            </section>
        </React.Fragment>
    );
}
