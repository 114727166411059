import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { useMedia, useWindowDimensions } from "../utils/utils";

import { Grid } from "swiper";

export default function MediaSwiper() {
    const media = useMedia();
    //const media = require("../utils/media.json");
    const { width } = useWindowDimensions();

    const columns = width >= 991 ? 4 : 2;

    return (
        <section className="meta_media">
            <div className="container-xl">
                <h2 className="mainheader1">METAMEDIA</h2>
                <Swiper
                    grid={{ rows: 3, fill: "row" }}
                    slidesPerView={columns}
                    slidesPerColumn={4}
                    slidesPerGroup={4}
                    spaceBetween={30}
                    modules={[Grid]}
                >
                    {media.map((m) => (
                        <SwiperSlide>
                            <a href={m.link}>
                                <img
                                    src={m.img}
                                    style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}
