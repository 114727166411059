import React from "react";

export default function Privacy() {
    return (
        <React.Fragment>
            <section class="first_block htbpad">
                <div class="container-xl">
                    <h1 class="betablocktext2 htbheader">TERMS & CONDITIONS</h1>
                    <div class="htbimg">
                        <img src="img/htbbull.png" />
                    </div>
                </div>
            </section>
            <section class="maintext">
                <div class="container-xl">
                    <div class="mediaheader2">
                        This privacy policy ("Policy") informs you of your
                        choices and our practices in relation to your
                        information (as defined below). For the purposes of this
                        policy, "we," "us," and "our" refers to "Metalevel," a
                        branding style of Luxe Media LLC, 3301 Chetumal Street,
                        Belize City, Belize.
                    </div>
                    <h3>Children</h3>
                    <p>
                        Our services are not available for use by children and
                        are intended for persons over the age of 18 years old
                        and 21 years old in some jurisdictions. Please refer to
                        your country's laws in accordance with age-appropriate
                        guidance.
                    </p>
                    <p>
                        To comply with the current 'UK Data Protection Act' for
                        Children, specifically the Age Appropriate Design Code
                        (also known as the Children's Act), risks have been
                        assessed. More information can be found at
                        https://ico.org.uk/for-organisations/childrens-code-hub/.
                    </p>
                    <p>
                        This policy will explain areas of our app or website
                        that may affect your privacy and personal details, how
                        we process, collect, manage and store those details, and
                        how your rights.
                    </p>
                    <p>
                        For this policy, "Information" means any information
                        relating to an identified or identifiable individual.
                        This includes information relating to your use of (a)
                        our mobile app ("Mobile App" the "Service"); (b)
                        www.metalevel-ai.com and any other dedicated websites
                        which link to this policy ("Website"). When you use the
                        app or you accept our rules and policies, which set out
                        how we handle your information, and you understand we
                        may collect, process, use and store your information as
                        described in this policy. "Payment" refers to deposits
                        made using tokens via your virtual wallet. If you do not
                        agree with this policy, you must not use our app or
                        website. If you change your mind in the future, you must
                        stop using our app or website, and you may exercise your
                        rights in relation to your information as set out in
                        this policy.
                    </p>
                    <p>
                        This Privacy Policy does not apply to information that
                        you may submit to third-party websites or mobile
                        applications that may link to the Websites or be linked
                        to on the Websites. We are not responsible for the
                        actions or privacy practices of third-party websites and
                        applications; please consult those websites and
                        applications directly to understand their privacy
                        practices.
                    </p>
                    <h3>PERSONAL INFORMATION WE COLLECT</h3>
                    <p>
                        <strong>
                            We may collect and use the following information
                            about you
                        </strong>
                    </p>
                    <p>
                        <strong>Information you provide to us:</strong>
                    </p>
                    <p>
                        We receive and store the information provided to us or
                        given to us in any other way, including your: name,
                        mailing address, phone number, email address, picture,
                        date of birth, payment information, registration
                        information, social media, and messaging platform
                        handle, optional biographic and demographic information,
                        processional and licensure information, information for
                        wallets you create or connect via our Websites, survey
                        responses, and any other information that you
                        voluntarily provide. This includes information that you
                        share with us on third-party websites and platforms.
                    </p>
                    <p>
                        Information collected via our customer support channels,
                        for example, when you contact us via email, you may
                        provide us with (a) your full name and email and (b) any
                        information you choose to provide to allow us to assist
                        you. This information is not used or shared for any
                        purpose other than to assist with your reason for
                        contact.
                    </p>
                    <p>
                        Information you provide when using the app or website:
                        You are only required to submit personal information
                        should you choose to opt into marketing emails, such as
                        newsletters and updates.
                    </p>
                    <p>
                        Information provided: It is the responsibility of you,
                        the 'user,' to ensure your details are accurate and up
                        to date and, where possible, to only provide such
                        information as is necessary when you contact us.
                    </p>
                    <p>
                        Information we automatically collect or is generated
                        about you when you register for newsletters or updates
                        or connect to our app or website:
                    </p>
                    <p>
                        Identifiers, such as your name, email address, IP
                        address, device and app ID, unique ID, location data,
                        and device information (such as model, brand, and
                        operating system).
                    </p>
                    <p>
                        Cookies: we use cookies and other similar technologies
                        (e.g., web beacons, log files, and scripts) ("Cookies")
                        to enhance your experience when using our services.
                        Cookies are small files that, when placed on your
                        device, enable us to provide certain features and
                        functionality. You have the option to permit the
                        installation of such Cookies or subsequently disable
                        them. You may accept all cookies or instruct the device
                        or web browser to provide notice at the time of
                        installation of cookies or refuse to accept all cookies
                        by adjusting the relevant cookie retention function in
                        your device. However, in the event of your refusal to
                        install cookies, the Game may be unable to operate as
                        designed. Information regarding your use of the website
                        or app, such as the date and time stamps of events and
                        interactions with our teams.
                    </p>
                    <p>
                        Location-based data - Using an app: is collected within
                        the app and can only be collected if you, the 'user,'
                        have your location services activated. When the app is
                        installed, it will ask for permission to allow the app
                        to access your location service; you can accept or deny
                        it. You can also go into your settings on your phone and
                        disable this at any time. Website: When you visit our
                        Websites or interact with our online services, we may
                        receive information about your location and your device,
                        including a unique identifier for your device. Location
                        information allows us to provide location-based
                        services, such as advertising and other personalized
                        content.
                    </p>
                    <p>
                        Our Websites may use "cookies" (please refer to our
                        Cookies Policy) tagging and other tracking technologies
                        to help enable us to enhance or personalize your online
                        experience. This information includes computer and
                        connection information such as statistics on your page
                        views, traffic to and from our Websites, referral URL,
                        ad data, your IP address, device identifiers,
                        transaction history, and your web log information.
                    </p>
                </div>
            </section>
        </React.Fragment>
    );
}
