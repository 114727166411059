import React from "react";
import { ConnectKitButton } from "connectkit";

export default function ConnectButton() {
    return (
        <ConnectKitButton.Custom>
            {({ isConnected, show, truncatedAddress }) => {
                return (
                    <button className="but2" onClick={show}>
                        <span>
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15 9V6H4.5C4.10218 6 3.72064 5.84196 3.43934 5.56066C3.15804 5.27936 3 4.89782 3 4.5C3 3.675 3.675 3 4.5 3H13.5V6"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M3 4.5V13.5C3 14.325 3.675 15 4.5 15H15V12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M13.5 9C13.1022 9 12.7206 9.15804 12.4393 9.43934C12.158 9.72064 12 10.1022 12 10.5C12 11.325 12.675 12 13.5 12H16.5V9H13.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                        {isConnected ? truncatedAddress : "Connect Wallet"}
                    </button>
                );
            }}
        </ConnectKitButton.Custom>
    );
}
