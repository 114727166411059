import React from "react";

import Countdown from "./Countdown";
import ConnectButton from "./ConnectButton";

export default function Topmenu() {
    return (
        <React.Fragment>
            <section className="topmenuholder">
                <div className="topmenuholder1">
                    <div className="container-fluid">
                        <div className="topmenuholder2">
                            <div className="logomob">
                                <a href="/">
                                    <img src="img/logo.svg" />
                                </a>
                            </div>
                            <div className="burger">
                                <a
                                    data-bs-toggle="offcanvas"
                                    href="#offcanvasExample"
                                    role="button"
                                    aria-controls="offcanvasExample"
                                >
                                    <img src="img/hamburger-menu-6.svg" />
                                </a>
                            </div>
                            <div className="logomenu">
                                <div className="logo">
                                    <a href="/">
                                        <img src="img/logo.svg" />
                                    </a>
                                </div>
                                <div className="topmenu">
                                    <ul>
                                        <li>
                                            <a href="/how_to_buy">How to Buy</a>
                                        </li>
                                        <li>
                                            <a href="/#page1">About</a>
                                        </li>
                                        <li>
                                            <a href="/#page2">Ecosystem</a>
                                        </li>
                                        <li>
                                            <a
                                                href="/media/Whitepaper.pdf"
                                                target="_blank"
                                            >
                                                Whitepaper
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#page4">Team</a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://gleam.io/pTDDQ/metalevel"
                                                target="_blank"
                                            >
                                                Win $250k
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#page5">FAQ</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="butholdertop">
                                <div className="langlist">
                                    <a
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        className="languagesel"
                                    >
                                        <svg
                                            className="svg-icon"
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                verticalAlign: "middle",
                                                fill: "currentColor",
                                                overflow: "hidden",
                                            }}
                                            viewBox="0 0 1024 1024"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M507.9 909.1c33.2 0 66.4-4.1 99.6-12.4-4.1-8.3-12.4-12.4-24.9-8.3 12.4-58.1 20.7-87.1 45.6-112 16.6-12.4 24.9-33.2 12.4-49.8-8.3-12.4-24.9-20.7-37.3-16.6-24.9 4.1-8.3-29-29-33.2-20.7-4.1-49.8-45.6-78.8-58.1-16.6-8.3-33.2-29-58.1-29-20.7 0-53.9 20.7-53.9 4.1 0-49.8-4.1-83-4.1-99.6 0-12.4-8.3-4.1 24.9-4.1 16.6 0 8.3-33.2 24.9-33.2 16.6 0 53.9 16.6 62.2 8.3 8.3-4.1 66.4 149.4 66.4 24.9 0-16.6-8.3-41.5 0-53.9 20.7-33.2 41.5-70.5 58.1-107.9-16.6-4.1-37.3-4.1-53.9 0-8.3 4.1 4.1 16.6-8.3 16.6-41.5 8.3-78.8-12.4-66.4-33.2 12.4-20.7 62.2-8.3 66.4-49.8 4.1-24.9 4.1-49.8 4.1-70.5 53.9 8.3 49.8-62.2-20.7-78.8h-20.7c-190.9 0-352.7 132.8-390.1 319.5 16.6 16.6 45.6 16.6 58.1-4.1-4.1-4.1-4.1-4.1 0-8.3 16.6 12.4 41.5 12.4 41.5 33.2 0 70.5 4.1 145.2 66.4 145.2 24.9 8.3 41.5 29 49.8 53.9 4.1 16.6 29 0 49.8 0 12.4 0 0 20.7 0 62.2s95.4 112 95.4 112c0 24.9 0 45.6 4.1 70.5-12.4 0-29 0-41.5 4.1 20.7 8.3 37.3 8.3 58 8.3z m373.5-531.2s-4.1 0-4.1-4.1c-24.9-66.4-87.1-16.6-66.4 37.3-116.2 87.1-83 149.4-45.6 182.6 20.7 20.7 37.3 41.5 49.8 66.4-12.4 33.2 37.3 24.9 70.5-24.9 16.6-41.5 20.7-87.1 20.7-132.8v-37.3c0-8.3 0-16.6-4.1-20.7-4.2-16.7-12.5-41.6-20.8-66.5z" />
                                            <path d="M512.1 946.5c-240.7 0-435.7-195-435.7-435.7S271.4 75 512.1 75s435.7 195 435.7 435.7-195 435.8-435.7 435.8z m0-830c-215.8 0-394.2 178.4-394.2 394.2S296.3 905 512.1 905s394.2-178.4 394.2-394.2-178.4-394.3-394.2-394.3z" />
                                        </svg>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|en');return false;"
                                                title="English"
                                                className="dropdown-item gflag nturl"
                                            >
                                                {" "}
                                                <span className="fi fi-gb"></span>{" "}
                                                English
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|zh-CN');return false;"
                                                title="Chinese (Simplified)"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-cn">
                                                    {" "}
                                                </span>{" "}
                                                Chinese{" "}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|nl');return false;"
                                                title="Dutch"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-nl"></span>{" "}
                                                Dutch
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|fr');return false;"
                                                title="French"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-fr"></span>{" "}
                                                French
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|de');return false;"
                                                title="German"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-de"></span>{" "}
                                                German
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|it');return false;"
                                                title="Italian"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-it"></span>{" "}
                                                Italian
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|ko');return false;"
                                                title="Korean"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-kp"></span>{" "}
                                                Korean
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|pt');return false;"
                                                title="Portuguese"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-pt"></span>{" "}
                                                Portuguese
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|ro');return false;"
                                                title="Romanian"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-ro"></span>{" "}
                                                Romanian
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|es');return false;"
                                                title="Spanish"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-es"></span>{" "}
                                                Spanish
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onclick="doGTranslate('en|tr');return false;"
                                                title="Turkish"
                                                className="dropdown-item gflag nturl"
                                            >
                                                <span className="fi fi-tr"></span>{" "}
                                                Turkish{" "}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a href="/presale" className="but1">
                                    <span>
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V5.625L10.875 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V6"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M10.5 1.5V6H15"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M1.5 11.25H9"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M6.75 13.5L9 11.25L6.75 9"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>
                                    Join Presale
                                </a>
                                <ConnectButton />
                            </div>
                        </div>
                    </div>
                </div>
                <Countdown />
            </section>
            <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                        <a href="/">
                            <img src="img/logo.svg" />
                        </a>
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <div className="footermenu">
                            <ul>
                                <li>
                                    <a href="/how_to_buy">How to Buy</a>
                                </li>
                                <li>
                                    <a href="/#page1" onclick="closecanv()">
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="#page2" onclick="closecanv()">
                                        Ecosystem
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/media/Whitepaper.pdf"
                                        target="_blank"
                                    >
                                        Whitepaper
                                    </a>
                                </li>
                                <li>
                                    <a href="#page4" onclick="closecanv()">
                                        Team
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://gleam.io/pTDDQ/metalevel"
                                        target="_blank"
                                    >
                                        Win $250k
                                    </a>
                                </li>
                                <li>
                                    <a href="#page5" onclick="closecanv()">
                                        FAQ
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="butholdertop mobvar">
                            <a href="/presale" className="but1">
                                <span>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V5.625L10.875 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V6"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                        <path
                                            d="M10.5 1.5V6H15"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                        <path
                                            d="M1.5 11.25H9"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                        <path
                                            d="M6.75 13.5L9 11.25L6.75 9"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                </span>
                                Join Presale
                            </a>
                            <ConnectButton />
                        </div>
                        <div
                            className="langlist dropup"
                            style={{ textAlign: "center", marginTop: "16px" }}
                        >
                            <a
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="languagesel"
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            >
                                <svg
                                    className="svg-icon"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        verticalAlign: "middle",
                                        fill: "currentColor",
                                        overflow: "hidden",
                                    }}
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M507.9 909.1c33.2 0 66.4-4.1 99.6-12.4-4.1-8.3-12.4-12.4-24.9-8.3 12.4-58.1 20.7-87.1 45.6-112 16.6-12.4 24.9-33.2 12.4-49.8-8.3-12.4-24.9-20.7-37.3-16.6-24.9 4.1-8.3-29-29-33.2-20.7-4.1-49.8-45.6-78.8-58.1-16.6-8.3-33.2-29-58.1-29-20.7 0-53.9 20.7-53.9 4.1 0-49.8-4.1-83-4.1-99.6 0-12.4-8.3-4.1 24.9-4.1 16.6 0 8.3-33.2 24.9-33.2 16.6 0 53.9 16.6 62.2 8.3 8.3-4.1 66.4 149.4 66.4 24.9 0-16.6-8.3-41.5 0-53.9 20.7-33.2 41.5-70.5 58.1-107.9-16.6-4.1-37.3-4.1-53.9 0-8.3 4.1 4.1 16.6-8.3 16.6-41.5 8.3-78.8-12.4-66.4-33.2 12.4-20.7 62.2-8.3 66.4-49.8 4.1-24.9 4.1-49.8 4.1-70.5 53.9 8.3 49.8-62.2-20.7-78.8h-20.7c-190.9 0-352.7 132.8-390.1 319.5 16.6 16.6 45.6 16.6 58.1-4.1-4.1-4.1-4.1-4.1 0-8.3 16.6 12.4 41.5 12.4 41.5 33.2 0 70.5 4.1 145.2 66.4 145.2 24.9 8.3 41.5 29 49.8 53.9 4.1 16.6 29 0 49.8 0 12.4 0 0 20.7 0 62.2s95.4 112 95.4 112c0 24.9 0 45.6 4.1 70.5-12.4 0-29 0-41.5 4.1 20.7 8.3 37.3 8.3 58 8.3z m373.5-531.2s-4.1 0-4.1-4.1c-24.9-66.4-87.1-16.6-66.4 37.3-116.2 87.1-83 149.4-45.6 182.6 20.7 20.7 37.3 41.5 49.8 66.4-12.4 33.2 37.3 24.9 70.5-24.9 16.6-41.5 20.7-87.1 20.7-132.8v-37.3c0-8.3 0-16.6-4.1-20.7-4.2-16.7-12.5-41.6-20.8-66.5z" />
                                    <path d="M512.1 946.5c-240.7 0-435.7-195-435.7-435.7S271.4 75 512.1 75s435.7 195 435.7 435.7-195 435.8-435.7 435.8z m0-830c-215.8 0-394.2 178.4-394.2 394.2S296.3 905 512.1 905s394.2-178.4 394.2-394.2-178.4-394.3-394.2-394.3z" />
                                </svg>
                            </a>
                            <ul className="dropdown-menu mobdrop">
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|en');return false;"
                                        title="English"
                                        className="dropdown-item gflag nturl"
                                    >
                                        {" "}
                                        <span className="fi fi-gb"></span>{" "}
                                        English
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|zh-CN');return false;"
                                        title="Chinese (Simplified)"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-cn"> </span>{" "}
                                        Chinese{" "}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|nl');return false;"
                                        title="Dutch"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-nl"></span> Dutch
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|fr');return false;"
                                        title="French"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-fr"></span>{" "}
                                        French
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|de');return false;"
                                        title="German"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-de"></span>{" "}
                                        German
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|it');return false;"
                                        title="Italian"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-it"></span>{" "}
                                        Italian
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|ko');return false;"
                                        title="Korean"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-kp"></span>{" "}
                                        Korean
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|pt');return false;"
                                        title="Portuguese"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-pt"></span>{" "}
                                        Portuguese
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|ro');return false;"
                                        title="Romanian"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-ro"></span>{" "}
                                        Romanian
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|es');return false;"
                                        title="Spanish"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-es"></span>{" "}
                                        Spanish
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onclick="doGTranslate('en|tr');return false;"
                                        title="Turkish"
                                        className="dropdown-item gflag nturl"
                                    >
                                        <span className="fi fi-tr"></span>{" "}
                                        Turkish{" "}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
