import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { WagmiConfig, createClient } from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { mainnet, goerli } from "wagmi/chains";
import { ToastContainer } from "react-toastify";

import Topmenu from "./components/Topmenu";
import Main from "./pages/Main";
import Presale from "./pages/Presale";
import HowToBuy from "./pages/HowToBuy";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import Media from "./pages/Media";
import Terms from "./pages/Terms";
import Cookies from "./pages/Cookies";

import "react-toastify/dist/ReactToastify.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";

const alchemyId = process.env.REACT_APP_ALCHEMY_ID;

const chains = process.env.REACT_APP_MAINNET ? [mainnet] : [goerli];

const client = createClient(
    getDefaultClient({
        appName: "Metalevel",
        alchemyId,
        chains,
    })
);

function App() {
    const router = createBrowserRouter([
        {
            path: "/presale",
            element: <Presale />,
        },
        {
            path: "/how_to_buy",
            element: <HowToBuy />,
        },
        {
            path: "/privacy",
            element: <Privacy />,
        },
        {
            path: "/media_info",
            element: <Media />,
        },
        {
            path: "/terms",
            element: <Terms />,
        },
        {
            path: "/cookies",
            element: <Cookies />,
        },
        {
            path: "/",
            element: <Main />,
        },
    ]);

    return (
        <WagmiConfig client={client}>
            <ConnectKitProvider>
                <ToastContainer position="bottom-center" theme="dark" />
                <Topmenu />
                <RouterProvider router={router} />
                <Footer />
            </ConnectKitProvider>
        </WagmiConfig>
    );
}

export default App;
