import React from "react";
import { BigNumber, utils } from "ethers";
import { useAccount } from "wagmi";

import { usePresaleData, fmt, getStageName } from "../utils/utils";
import ConnectButton from "../components/ConnectButton";
import Claim from "../components/Claim";
import Calculator from "../components/Calculator";
import Buy from "../components/Buy";

export default function Presale() {
    const {
        stage,
        soldOnStage,
        currentPrice,
        nextPrice,
        remainingOnStage,
        progressShare,
        usdCurrent,
        usdNext,
    } = usePresaleData();
    const { isConnected } = useAccount();

    return (
        <React.Fragment>
            <section class="first_block htbpad">
                <div class="container-xl">
                    <div class="preheader">get in early to receive more</div>
                    <div class="presaleholder">
                        <h1 class="betablocktext2 htbheader preheaderyellow">
                            <span class="yellow">Live now</span>{" "}
                            <span>$MLVL</span> pre-sale
                        </h1>
                    </div>
                    <div class="progresslineholder">
                        <div class="flag1">
                            <span>
                                <img src="img/flag1.svg" />
                            </span>
                        </div>
                        <div class="flag1 rightflag">
                            <span>
                                <img src="img/flag2.svg" />
                            </span>
                        </div>
                        <div class="pregressline">
                            <div
                                class="progressfill"
                                style={{
                                    width: `${progressShare}%`,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div class="currentcalculation">
                        <div class="currentcalculation_left">
                            Currently: <span>{getStageName(stage)}</span>
                        </div>
                        <div class="currentcalculation_right">
                            ${fmt(usdCurrent)} /
                            <span>${fmt(usdNext)} raised</span>
                        </div>
                    </div>
                    <div class="underbcomp">
                        <div class="tic">
                            <div class="topcalc1">
                                <div class="topcalc2">{fmt(soldOnStage)}</div>
                                <div class="topcalc3">tokens sold</div>
                            </div>
                            <div class="topcalc4">
                                <div class="pretext1">
                                    {fmt(remainingOnStage)}
                                </div>
                                <div class="pretext2">remaining</div>
                            </div>
                        </div>
                        <div class="tic">
                            <div class="pretext3">
                                {getStageName(stage)} price:{" "}
                                <span class="pretext4">
                                    ${utils.formatUnits(currentPrice)}
                                </span>{" "}
                                USDT = 1 $MLVL
                            </div>
                            {stage < 8 && (
                                <div class="pretext3">
                                    Next stage price:{" "}
                                    <span class="pretext5">
                                        ${utils.formatUnits(nextPrice)}
                                    </span>{" "}
                                    USDT = 1 $MLVL
                                </div>
                            )}
                        </div>
                        {isConnected ? <Buy /> : <ConnectButton />}
                    </div>
                    <div class="purchaseml">
                        <Claim />
                        <div class="imahecontainer2">
                            <div>
                                <img src="img/ssl1.png" />
                            </div>
                            <div>
                                <img src="img/ssl2.png" />
                            </div>
                            <div>
                                <img src="img/ssl3.png" />
                            </div>
                            <div>
                                <img src="img/ssl4.png" />
                            </div>
                            <div>
                                <a
                                    href="https://coinsult.net/projects/metalevel"
                                    target="_blank"
                                >
                                    <img
                                        height="50"
                                        src="https://coinsult.net/wp-content/uploads/2021/11/coinsult_logo.svg"
                                    />
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://github.com/solidproof/projects/blob/main/MetaLevel/SmartContract_Audit_Solidproof_MetaLevel.pdf"
                                    target="_blank"
                                >
                                    <img height="50" src="img/ssl6.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="newbuy">
                        <div class="newbuyimg">
                            <img class="abn2" src="img/mini1.png" />
                        </div>
                        <div class="center_header22">
                            <h2 class="mainheader1 whitetext222">
                                buy $mlmv today
                            </h2>
                        </div>
                        <div class="buttonholder10">
                            <a
                                href="/media/Whitepaper.pdf"
                                target="_blank"
                                class="but5"
                            >
                                Whitepaper
                            </a>
                            <a href="/how_to_buy" class="but6 corrected4">
                                <span>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15 9V6H4.5C4.10218 6 3.72064 5.84196 3.43934 5.56066C3.15804 5.27936 3 4.89782 3 4.5C3 3.675 3.675 3 4.5 3H13.5V6"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M3 4.5V13.5C3 14.325 3.675 15 4.5 15H15V12"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M13.5 9C13.1022 9 12.7206 9.15804 12.4393 9.43934C12.158 9.72064 12 10.1022 12 10.5C12 11.325 12.675 12 13.5 12H16.5V9H13.5Z"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </span>
                                How to Buy
                            </a>
                        </div>
                        <div class="icon_g_1">
                            <div>
                                <img src="img/icon_g_1.png" />
                            </div>
                            <div>
                                <img src="img/icon_g_2.png" />
                            </div>
                            <div>
                                <img src="img/icon_g_3.png" />
                            </div>
                        </div>
                    </div>
                    <Calculator />
                </div>
            </section>
        </React.Fragment>
    );
}
