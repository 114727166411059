import React from "react";
import { getProvider } from "@wagmi/core";
import { useContract, useSigner, erc20ABI } from "wagmi";
import { BigNumber } from "ethers";
import abi from "./presaleABI.json";
import axios from "axios";

export function fmt(x) {
    let [a, b] = x.toString().split(".");
    let res = "";
    while (a.length > 3) {
        if (res.length > 0) {
            res = a.substr(a.length - 3) + "," + res;
        } else {
            res = a.substr(a.length - 3);
        }
        a = a.slice(0, a.length - 3);
    }
    if (a.length > 0) {
        if (res.length == 0) {
            res = a;
        } else {
            res = a + "," + res;
        }
    }

    if (b) {
        res = res + b;
    }
    return res;
}

export function getStageName(stage) {
    if (stage === 0) {
        return "Beta Sale";
    } else if (stage) {
        return `Stage ${stage}`;
    } else {
        return "";
    }
}

export function truncateAddress(address) {
    const truncateRegex = /^(0x[a-zA-Z0-9]{8})[a-zA-Z0-9]+([a-zA-Z0-9]{8})$/;
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
}

export function usePresale() {
    const { data: signer } = useSigner();
    const provider = getProvider();
    return useContract({
        address: process.env.REACT_APP_PRESALE_ADDRESS,
        abi,
        signerOrProvider: signer ?? provider,
    });
}

export function useUSDT() {
    const { data: signer } = useSigner();
    const provider = getProvider();
    return useContract({
        address: process.env.REACT_APP_USDT_ADDRESS,
        abi: erc20ABI,
        signerOrProvider: signer ?? provider,
    });
}

const totalUsdUpToStage = [
    "0",
    "1120000",
    "2975000",
    "5460000",
    "7215000",
    "9170000",
    "11325000",
    "14535000",
    "17190000",
    "23100000",
].map((i) => BigNumber.from(i));

export function usePresaleData() {
    const presale = usePresale();

    const [stage, setStage] = React.useState(0);

    const [soldOnStage, setSoldOnStage] = React.useState(BigNumber.from(0));
    const [totalUpToStage, setTotalUpToStage] = React.useState(
        BigNumber.from(0)
    );

    const [currentPrice, setCurrentPrice] = React.useState(BigNumber.from(0));
    const [nextPrice, setNextPrice] = React.useState(BigNumber.from(0));

    const [ethPrice, setEthPrice] = React.useState(null);

    const [totalSold, setTotalSold] = React.useState(BigNumber.from(0));
    const [totalAmount, setTotalAmount] = React.useState(BigNumber.from(0));

    const remainingOnStage = totalUpToStage.sub(totalSold);

    const usdCurrent = totalUsdUpToStage[stage].add(
        soldOnStage.mul(currentPrice).div(BigNumber.from(10).pow(18))
    );
    const usdNext = totalUsdUpToStage[stage + 1];

    const progressShareBN = soldOnStage.add(remainingOnStage).gt(0)
        ? BigNumber.from(10000)
              .mul(soldOnStage)
              .div(soldOnStage.add(remainingOnStage))
        : BigNumber.from(0);
    const progressShare = progressShareBN.toNumber() / 100;

    React.useEffect(() => {
        if (presale) {
            presale.currentStep().then((s) => {
                const snum = s.toNumber();
                setStage(snum);

                presale.token_amount(snum).then((a) => setTotalUpToStage(a));

                if (snum < 8) {
                    presale.token_price(snum + 1).then((a) => setNextPrice(a));
                }
            });
            presale.getSoldOnCurrentStage().then((s) => setSoldOnStage(s));
            presale.getCurrentPrice().then((p) => setCurrentPrice(p));
            presale.totalTokensSold().then((a) => setTotalSold(a));
            presale.getTotalPresaleAmount().then((a) => setTotalAmount(a));
            presale.getLatestPrice().then((p) => setEthPrice(p));
        }
    }, [presale]);

    return {
        stage,
        soldOnStage,
        totalUpToStage,
        currentPrice,
        nextPrice,
        totalSold,
        totalAmount,
        ethPrice,
        remainingOnStage,
        usdCurrent,
        usdNext,
        progressShare,
    };
}

export function useMedia() {
    const [media, setMedia] = React.useState([]);

    const url = "/json_media.php";

    React.useEffect(() => {
        axios.get(url).then((r) => {
            if (Array.isArray(r.data)) {
                setMedia(r.data);
            }
        });
    }, []);

    return media;
}

export function useTimer() {
    const [timer, setTimer] = React.useState(new Date());

    const url = "/json_timer.php";

    React.useEffect(() => {
        axios.get(url).then((r) => {
            if (r.data && r.data.timer) {
                setTimer(new Date(r.data.timer));
            }
        });
    }, []);

    return timer;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
