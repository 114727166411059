import React from "react";

export default function Cookies() {
    return (
        <React.Fragment>
            <section class="first_block htbpad">
                <div class="container-xl">
                    <h1 class="betablocktext2 htbheader">COOKIE POLICY</h1>
                    <div class="htbimg">
                        <img src="img/htbbull.png" />
                    </div>
                </div>
            </section>
            <section class="maintext">
                <div class="container-xl">
                    <div class="mediaheader2">
                        For the purposes of this Cookie Policy, "we," "us," and
                        "our" refer to "Metalevel," a branding style of Luxe
                        Media LLC, 3402 Chetumal Street, Belize City, Belize.
                    </div>
                    <div class="mediaheader2">
                        This Cookie Policy applies to the following "Services"
                        www.metalevel-ai.com and any other dedicated websites,
                        apps, and any products, forums, and services we offer
                        through the app or website or another dedicated website
                        that we may offer time to time or otherwise provided by
                        us.
                    </div>
                    <div class="mediaheader2">
                        You can change your Cookie preferences or withdraw
                        consent using the Privacy Settings.
                    </div>
                    <h3>What is a Cookie?</h3>
                    <p>
                        Cookies are text files containing small amounts of
                        information placed on your device when you visit a
                        website. They are widely used to make websites work more
                        efficiently, as well as to provide information to the
                        owners of the website to generally improve the user
                        experience, for example, by remembering your preferences
                        or offering personalized content and advertisement.
                    </p>
                    <p>
                        Functions usually performed by a cookie can also be
                        achieved by similar technologies, such as log files,
                        pixel tags, web beacons, clear GIFs, and device IDs. In
                        this Cookie Policy, we will refer to them collectively
                        as "Cookies." How do we use Cookies?
                    </p>
                    <p>We may use Cookies to:</p>
                    <p>
                        - verify login information and allow users to find
                        friends who use a Service;
                    </p>
                    <p>
                        - track traffic flow and patterns of travel in
                        connection with a Service;
                    </p>
                    <p>
                        - understand the total number of users of a Service on
                        an ongoing basis and the types of devices;
                    </p>
                    <p>
                        - monitor the performance of a Service to continually
                        improve it;
                    </p>
                    <p>- customize and enhance your online experience;</p>
                    <p>- provide customer service to you; and</p>
                    <p>- serve third parties' adverts within our services.</p>
                    <h3>What Types of Cookies Do We Use?</h3>
                    <p>
                        The types of Cookies used by our partners and us in
                        connection with a Service can be classified into these
                        categories: 'Necessary Cookies', 'Functionality
                        Cookies', 'Analytics Cookies', and 'Advertising and
                        Tracking Cookies.' We have set out below some further
                        information about each category, purpose, and duration
                        of the Cookie's third parties, and we set. You may
                        disable Cookies at any time by using the Privacy
                        Settings, which may affect how the Service functions.
                    </p>

                    <p class="nopadding">
                        <strong>Necessary Cookies</strong>
                    </p>
                    <p>
                        Necessary Cookies enable core functionality such as
                        security, network management, and accessibility.
                    </p>
                    <p class="nopadding">
                        <strong>Functional Cookies</strong>
                    </p>
                    <p>
                        Functional Cookies record information about your choices
                        and allow us to tailor a Service to you.
                    </p>
                    <p>
                        These Cookies mean that when you continue to use or come
                        back to use a Service, we can provide you with our
                        services as you have previously asked for them. For
                        example, these Cookies allow us to show you when you are
                        logged in and store your preferences, such as your
                        language preferences.
                    </p>
                    <p class="nopadding">
                        <strong>Analytics Cookies</strong>
                    </p>
                    <p>
                        Analytics Cookies analyze how our service is accessed,
                        used, or performs to provide you with a better user
                        experience and maintain, operate and continually improve
                        a Service.
                    </p>
                    <p>These cookies allow us:</p>
                    <p>
                        - better understand users of a Service so that we can
                        improve how we present our content;
                    </p>
                    <p>
                        - test different design ideas for particular features;
                    </p>
                    <p>- determine the number of unique users of a Service;</p>
                    <p>
                        - improve a Service by measuring any errors that occur;
                        and
                    </p>
                    <p>
                        - conduct research and diagnostics to improve product
                        offerings.
                    </p>
                </div>
            </section>
        </React.Fragment>
    );
}
