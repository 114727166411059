import React from "react";
import { useAccount } from "wagmi";

import ConnectButton from "./ConnectButton";
import { BigNumber } from "ethers";
import { usePresale } from "../utils/utils";

export default function Claim() {
    const { isConnected, address } = useAccount();
    const [balance, setBalance] = React.useState(BigNumber.from(0));
    const [claimStart, setClaimStart] = React.useState(null);
    const presale = usePresale();

    React.useEffect(() => {
        if (isConnected && presale) {
            presale.userDeposits(address).then((b) => setBalance(b));
            presale.claimStart().then((s) => setClaimStart(s.toNumber()));
        }
    }, [isConnected, address, presale]);

    const canClaim = claimStart && claimStart <= Math.floor(Date.now() / 1000);

    const onClaim = () => {
        if (presale) {
            presale.claim();
        }
    };

    return (
        <React.Fragment>
            <div className="purchimg">
                <img className="abn" src="img/mini2.png" alt="" />
            </div>
            <div className="center_header22 padtopr">
                <h2 className="mainheader1 whitetext222">Claim $mlmv</h2>
            </div>
            <div className="buttonholder9">
                {isConnected ? (
                    <React.Fragment>
                        {canClaim && (
                            <a href="#null" className="but2" onClick={onClaim}>
                                <span>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15 9V6H4.5C4.10218 6 3.72064 5.84196 3.43934 5.56066C3.15804 5.27936 3 4.89782 3 4.5C3 3.675 3.675 3 4.5 3H13.5V6"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                        <path
                                            d="M3 4.5V13.5C3 14.325 3.675 15 4.5 15H15V12"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                        <path
                                            d="M13.5 9C13.1022 9 12.7206 9.15804 12.4393 9.43934C12.158 9.72064 12 10.1022 12 10.5C12 11.325 12.675 12 13.5 12H16.5V9H13.5Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                </span>
                                Claim
                            </a>
                        )}
                        <a className="but6 cerection1">
                            Claimable Balance:{" "}
                            {balance.div(BigNumber.from(10).pow(18)).toString()}{" "}
                            MLVL
                        </a>
                    </React.Fragment>
                ) : (
                    <ConnectButton />
                )}
            </div>
        </React.Fragment>
    );
}
