import React from "react";
import { usePresale, useTimer } from "../utils/utils";

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return targetDate ? countDown : null;
};

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export default function Countdown() {
    const [presaleStart, setPresaleStart] = React.useState(
        new Date(2050, 1, 1)
    );
    const presaleEnd = useTimer();
    const countDown = useCountdown(presaleEnd);
    const presale = usePresale();

    React.useEffect(() => {
        if (presale) {
            presale
                .startTime()
                .then((startTime) =>
                    setPresaleStart(startTime.toNumber() * 1000)
                );
        }
    }, [presale]);

    const [days, hours, minutes, seconds] = getReturnValues(countDown);

    const isStarted = new Date() > new Date(presaleStart);
    const isFinished = new Date() > new Date(presaleEnd);

    return (
        <div className="yellowline">
            <div className="container-xl">
                <div className="yellowcontet">
                    <div className="yellowblock1">
                        <div className="yellowtext1">
                            <span>
                                <img src="img/rocket.svg" />
                            </span>
                            {isStarted
                                ? "BETA SALE launched"
                                : "BETA SALE before launch"}
                        </div>
                        <div className="yellowtext1">
                            <span>
                                <img src="img/clock.svg" />
                            </span>
                            {isFinished ? "presale ended" : "presale ends in"}
                        </div>
                    </div>
                    {countDown > 0 && (
                        <div className="yellowblock2">
                            <span id="days2">{days}</span> days{" "}
                            <span id="hours2">{hours}</span> hours{" "}
                            <span id="minutes2">{minutes}</span> minutes{" "}
                            <span id="seconds2">{seconds}</span> seconds
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
