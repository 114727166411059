import React from "react";

export default function Terms() {
    return (
        <React.Fragment>
            <section class="first_block htbpad">
                <div class="container-xl">
                    <h1 class="betablocktext2 htbheader">TERMS & CONDITIONS</h1>
                    <div class="htbimg">
                        <img src="img/htbbull.png" />
                    </div>
                </div>
            </section>
            <section class="maintext">
                <div class="container-xl">
                    <div class="mediaheader2">
                        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE
                        USING OUR WEBSITE OR APP.
                    </div>
                    <p>
                        "Metalevel" is a branding style of Luxe Media LLC, 3402
                        Chetumal Street, Belize City, Belize.
                    </p>
                    <p>
                        You should read these Terms because they contain our
                        legal commitments to you and several DOs and DON'Ts,
                        which you need to be aware of when you use our Services.
                        Please read these Terms carefully to make sure you
                        understand them. Using our Services, you are
                        automatically deemed to agree to accept and be legally
                        bound by these Terms. To avoid doubt, you should not
                        proceed to access or use our Services if you do not
                        agree with the Terms.
                    </p>
                    <p>
                        You should also read our Privacy Policy. The Privacy
                        Policy explains how we use your data.
                    </p>
                    <p>
                        Please contact us to discuss if you think there is a
                        mistake in these terms or have any queries.
                    </p>
                    <p>
                        If we have to contact you, we will write to you at the
                        email address you provided. It is, therefore, very
                        important that you confirm you have provided a
                        legitimate email address used by you, and by proceeding
                        with the use of our Services, you warrant that you have
                        done so. We will only contact you with explicit consent
                        for us to do so. You will only receive emails when you
                        have registered to receive our newsletter and updates.
                    </p>
                    <p>
                        When we use the words "writing" or "written" in these
                        terms, this includes emails. In this document,
                        "Metalevel," "we," or "us" refers to "Metalevel, a
                        branding style of Luxe Media LLC."
                    </p>
                    <h3>Changes to Terms</h3>
                    <p>
                        We can update and change these Terms from time to time,
                        and the most current version of these Terms will be
                        posted on the website and relevant app, and you may be
                        invited to review and accept the revised Terms to
                        continue using the Services. We suggest all users
                        regularly check the terms on the website and in-app,
                        where any changes will be posted. You can print and save
                        a copy of these Terms for future reference.
                    </p>
                    <p>
                        We may require you to update the software to be able to
                        use the Services, provided that the Services will
                        continue to match the description of it that we provided
                        to you before.
                    </p>
                    <p>
                        The associated software may be upgraded to reflect
                        changes in an operating system.
                    </p>
                    <p>
                        By continuing to use the Services, you will be deemed to
                        have accepted the Terms as varied from time to time.
                    </p>
                    <h3>Using Our Service</h3>
                    <p>
                        To use our service, you will need to have a virtual
                        wallet.
                    </p>
                    <p>
                        We draw your attention to reading the terms of use of
                        your wallet provider.
                    </p>
                    <p>
                        Security is important to Metalevel, and you, therefore,
                        agree not to share your wallet access with any other
                        user or third party or knowingly carry out any activity
                        which enables a third party to access or use your
                        account. If we believe, acting at our discretion, that
                        your account is used inappropriately, we reserve the
                        right to suspend or terminate or cease to support your
                        account without liability.
                    </p>
                    <p>
                        We cannot check the identities of people using our
                        Services and will not be liable if your wallet
                        connection or account is used by someone else. Suppose
                        you become aware of any unauthorized use of your login.
                        In that case, you should notify us immediately here. The
                        subject should read 'Security Breach' though please note
                        that we may need to verify your identity and validate
                        your account ownership. Please be alert for other
                        websites and services which may pretend to be us or be
                        associated with us. If in doubt, please contact;
                        admin@metalevel-ai.com.
                    </p>
                    <h3>Removing Data</h3>
                    <p>
                        If you would like us to delete any data we may hold on
                        you, please contact us by emailing
                        admin@metalevel-ai.com.
                    </p>
                    <p>
                        Whereas we do not require personal information or
                        identifiable information upon signing up to use our
                        service, we may (if you have provided an email address
                        to us, or permitted us to contact you in another way)
                        still hold details for you, therefore, If you wish for
                        your data to be removed, you must specify this when
                        emailing your request for your data is removed. If you
                        do not request the deletion of your data, we will hold
                        this information as outlined in our Privacy Policy.
                    </p>
                    <h3>Rules of Use</h3>
                    <p>
                        You undertake and agree to adhere to and abide by the
                        following rules ("the Rules"). You agree that you will
                        not post, distribute, or otherwise make available any
                        data, text, message, graphic, or computer file that we
                        believe:
                    </p>

                    <p>- is a personal attack on other individuals;</p>
                    <p>
                        - bullies, stalkers, or otherwise harasses any other
                        user of our Services;
                    </p>
                    <p>
                        - is vulgar, obscene, or sexually explicit (language or
                        images);
                    </p>
                    <p>
                        - is offensive, sexist, racist, or discriminatory in any
                        way;
                    </p>
                    <p>- is a form of cheating;</p>
                    <p>
                        - encourages or advocates illegal activity or the
                        discussion of illegal activities with the intent to
                        commit them;
                    </p>
                    <p>
                        - Infringes and/or violates any right of a third party,
                        including but not limited to: (a) copyright, patent,
                        trademark, or other proprietary rights; (b) right of
                        privacy (specifically, you must not distribute another
                        person's personal information of any kind without their
                        express permission) or publicity; (c) any
                        confidentiality obligation;
                    </p>
                </div>
            </section>
        </React.Fragment>
    );
}
